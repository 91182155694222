.container {
  padding: 30px;
}

.bold {
  font-weight: 600;
}

.container Grid {
  padding: 30px !important;
}

.item {
  padding: 9px;
}

.marginRight {
  margin-right: 16px;
}

.backgroundGray {
  background: rgba(36, 69, 167, 0.06);
}

.textMuted {
  color: #888fa9;
}
