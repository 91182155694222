.containerHeadPlans {
  padding: 25px 30px;
  border: 1px solid #f0efef;
  border-radius: 8px 8px 0 0 !important;
  -webkit-border-radius: 8px 8px 0 0 !important;
  -moz-border-radius: 8px 8px 0 0 !important;
  -ms-border-radius: 8px 8px 0 0 !important;
  -o-border-radius: 8px 8px 0 0 !important;
}

.containerAvailablePlan {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #f0efef;
}

.containerBenefits {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #f0efef;
}

.containerSubscriptionButton {
  padding: 0 16px;
}

.checkIcon {
  color: #17b471;
  margin-right: 16px;
}

.chipCurrentPlan {
  background-color: #526fff !important;
  margin-bottom: 20px;
}
