.separateButtons {
  margin-right: 1.5rem !important;
}

.cardInputStripe {
  padding: 16px 23px 17px;
  border-radius: 4px;
  background-color: #f1f3f9;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  -webkit-border-radius: 4px;
}

.errorText {
  color: #ff5225;
  font-size: 12px;
}
