.containerBreadcrumbs {
  border: 1px solid #e6e6e6;
}

.containerCardCheckout {
  margin-top: 1.5rem;
}

.checkIcon {
  color: #17b471;
  margin-right: 15px;
}

.separateButtons {
  margin-right: 20px !important;
}

.spacing {
  padding: 10px 0 10px 0;
}
