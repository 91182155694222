.label {
  margin-bottom: 15px;
  font-weight: bold;
  display: block;
  color: #4e5978;
  font-size: 14px;
}

.assignedTo {
  width: 100%;
  background: #f1f3f9;
  border: 0px solid #f1f3f9;
  height: 50px;
  padding: 0 5px;
}
