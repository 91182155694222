.tab {
  margin: 0;
  padding: 0 45px !important;
  height: 100%;
  color: #2445a7;
  background: white;
}

.borderRight {
  border-right: 1px solid #f0efef;
}

.borderLeftRight {
  border-left: 1px solid #2445a7 !important;
  border-right: 1px solid #2445a7 !important;
}

.activeTab {
  font-weight: bold !important;
  color: #2445a7 !important;
  background: #f1f3f9 !important;
}

.tabsContainer {
  border: 1px solid #888fa9;
  width: 100%;
  max-width: 420px;
  display: inline-flex;
  padding: 0;
  margin: 0;
  height: 30px !important;
  border-radius: 10px;
  background-color: #ffffff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.flexEnd {
  display: flex;
  align-items: flex-end;
}
