.image {
  border-radius: 10px;
  height: 245px;
  max-height: 300px;
}

.image img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.padding {
  padding: 1rem 2rem;
}
