.pop {
  top: 195px !important;
  left: -34px !important;
  border-radius: 0 !important;
}

.item {
  padding: 5px 10px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.itemIcon {
  font-size: 18px;
  position: absolute;
  left: -20px;
}
