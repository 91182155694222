.list {
  padding: 1rem 0;
  display: flex;
  flex-wrap: wrap;
}

.marginTop {
  margin-top: 2rem;
}

.container {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.footer {
  border-top: 1px solid rgb(235, 235, 235);
  padding: 1.5rem 0;
  text-align: center;
}

.footer .viewAll {
  text-decoration: none;
  color: #4e5978;
}

.noRegisters {
  text-align: center;
  padding: 1rem;
}

.titleWithButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2.5rem;
  width: 100%;
}

.containerMainVideo {
  padding: 2rem;
}

.containerMainVideo video {
  margin: 1rem 0;
}
