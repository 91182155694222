.iconInfo {
  width: 40px;
  height: 40px;
  background: #2445a7;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.item {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 50px;
}

.title {
  width: 100%;
  text-align: center;
  padding: 16px 0;
}

.text {
  color: #979797;
  text-align: center;
}
