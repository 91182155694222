.item {
  padding: 1rem;
  display: flex;
  width: 100%;
  min-width: 100%;
}

.image {
  border-radius: 10px;
  height: 156px;
  max-height: 156px;
}

.image img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.itemContent h3,
p {
  margin: 5px;
}

.itemContent h3 {
  color: #2445a7;
}

.itemContent p {
  color: #4e5978;
}

.itemContent {
  width: 100%;
}

.alignContent {
  display: flex;
  justify-content: space-between;
}

.floatRight {
  text-align: right;
}

.text {
  margin: 0;
  padding: 0 1rem;
  font-size: 16px;
}

.noMargin {
  margin: 0;
}

.textMuted {
  color: #888fa9;
}

.link {
  text-decoration: none;
  color: #2445a7;
}
