.containerButtonMenu {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  height: 100%;
}

.divider {
  padding: 1rem;
  border-right-width: 1.5px;
  border-color: #edeef1;
  border-right-style: solid;
  border-radius: 0;
  z-index: 999;
}

.dividerMobile {
  padding: 1rem 0.5rem 1rem 0.5rem;
  border-right-width: 1.5px;
  border-color: #edeef1;
  border-right-style: solid;
  border-radius: 0;
  z-index: 999;
}

.subHeader {
  height: 5rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: #fff;
  box-shadow: 0px 1px 0px 0px #e9ebee;
}
