/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.PhoneInputCountryIcon {
  width: 30px !important;
  height: 30px !important;
}

.PhoneInputCountryIcon--border {
  background-color: #f1f3f9 !important;
  box-shadow: none !important;
}

.PhoneInputInput {
  padding: 5px !important;
  background: transparent !important;
  border: none !important;
  font-size: 15px;
}

.PhoneInputInput:focus {
  outline: none !important;
}

.PhoneInputCountryIconImg {
  width: 30px !important;
  height: 30px !important;
}

.PhoneInputCountrySelect {
  font-size: 17px;
  padding: 10px;
  font-family: 'Lexend';
}

.PhoneInputCountrySelect:focus > option:checked {
  background: #2445a7 !important;
  color: white !important;
}

.blurNotSubscribed {
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
}

.slider-featured-vendors {
  display: flex;
  gap: 10px;
  height: 270px;
  padding: 0 10px 0 10px;
  overflow-x: scroll;
  overscroll-behavior-x: contain;
  scroll-snap-type: x proximity;
}

@keyframes spinner-cover-r {
  0%,
  100% {
    animation-timing-function: cubic-bezier(0.2 0 0.8 0.8);
  }
  50% {
    animation-timing-function: cubic-bezier(0.2 0.2 0.8 1);
  }
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinner-cover-s {
  0%,
  100% {
    animation-timing-function: cubic-bezier(0.2 0 0.8 0.8);
  }
  50% {
    animation-timing-function: cubic-bezier(0.2 0.2 0.8 1);
  }
  0% {
    transform: translate(-30px, -30px) scale(0);
  }
  50% {
    transform: translate(-30px, -30px) scale(1);
  }
  100% {
    transform: translate(-30px, -30px) scale(0);
  }
}
.MuiTableCell-root {
  padding: 17px 0 !important;
}
.spinner-cover > div {
  transform: translate(0px, -15px);
}
.spinner-cover > div > div {
  animation: spinner-cover-r 1s linear infinite;
  transform-origin: 100px 100px;
}
.spinner-cover > div > div > div {
  position: absolute;
  transform: translate(100px, 82px);
}
.spinner-cover > div > div > div > div {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #2445a7;
  animation: spinner-cover-s 1s linear infinite;
}
.spinner-cover > div > div:last-child {
  animation-delay: -0.5s;
}
.spinner-cover > div > div:last-child > div > div {
  animation-delay: -0.5s;
  background: #f32445;
}
.spinner-interwind {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}
.spinner-cover {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.spinner-cover div {
  box-sizing: content-box;
}

.stripes {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 0,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 0,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 0,
    transparent
  );
  background-size: 1rem 1rem;
}

@keyframes progress-bar-stripes {
  0% {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (max-width: 768px) {
  .MuiTableCell-root {
    padding: 17px 10px !important;
  }
}

.highlight {
  background-color: #ffd900;
}
