.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  flex-wrap: wrap;
}

.text {
  font-family: Manrope;
  font-weight: bold !important;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.line {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid rgb(240, 240, 240);
  border-top: 1px solid rgb(240, 240, 240);
}

.lineNumber {
  font-weight: bold !important;
}

.textMuted {
  color: #888fa9;
}
