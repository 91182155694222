.activeButton {
  background-color: #f1f3f9;
  color: black;
  padding: 16px;
  margin: 0;
  border-radius: 16px;
  cursor: pointer;
}

.inactiveButton {
  color: #2445a7;
  padding: 16px;
  cursor: pointer;
}
