.table {
  max-width: 900;
}

.registered {
  color: #17b471 !important;
  background: rgba(23, 180, 113, 0.06);
}

.unregistered {
  color: #ff5225 !important;
  background: rgba(204, 77, 46, 0.06);
}

.archived {
  color: #b2b8cf !important;
  background: rgba(178, 184, 207, 0.06);
}

.tableHeadBg {
  padding: 13px !important;
  box-shadow: 0 1px 0 0 #e9ebee;
  background-color: #f1f3f9 !important;
}
.tableDropdown {
  font-style: normal !important;
}
