.titleSelect {
  font-weight: 600;
  padding: 15px;
  border-bottom: 1px solid rgba(78, 89, 120, 0.12);
  font-size: 16px;
}

.noPadding ul {
  padding: 0;
}

.inputSearch {
  width: 100%;
  height: 42px;
}

.inputBase {
  height: 42px;
  border-bottom: 1px solid rgba(78, 89, 120, 0.12);
}

.adormentSearch {
  margin-left: 10px;
}

.menuItemTitle {
  font-size: 14px;
  font-weight: 600;
}

.menuItemContent {
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.textMuted {
  color: #4e5978;
}

.w100 {
  width: 100%;
}
