.card {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px 10px 10px 0;
  background-color: #f1f3f9;
  border-radius: 4px;
}
.title,
.subtitle,
.buttons {
  width: 100%;
  text-align: right;
}

.subtitle {
  color: #888fa9;
}
